<template>
    <div class="auth-header d-flex align-center">
        <div class="link-reset ml-n3" @click="$router.go(-1)">
            <v-icon large>mdi-chevron-left</v-icon>
        </div>
        <div class="text-h4">{{ title }}</div>
    </div>
</template>

<script>
export default {
  name: "AuthHeader",
  props: {
    title: {
      type: String,
      required: true
    },
    to: {
      type: String,
      default: '/app/home'
    }
  }
}
</script>
