<template>
    <div class="fill-height">
        <v-container>
            <auth-header :title="$t('dialogs.reset_password')"/>
            <form class="auth-form mt-8" @submit.prevent="send">
                <v-row class="no-gutters" v-if="errors.token || errors.token">
                    <v-col class="no-gutters" cols="12">
                        <span class="deep-orange--text">{{ $t('dialogs.error_token_email') }}</span>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <text-field :label="$t('dialogs.new_password')"
                                    type="password"
                                    v-model="form.password"
                                    :error-messages="errors.password"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <text-field :label="$t('dialogs.verify_password')"
                                    type="password"
                                    v-model="form.password_confirmation"
                                    :error-messages="errors.password_confirmation"
                        />
                    </v-col>
                </v-row>

                <v-row>
                    <v-col>
                        <btn-large class="btn-accent mt-8"
                                   type="submit"
                                   :text="$t('dialogs.update')"/>
                    </v-col>
                </v-row>
            </form>
        </v-container>
    </div>
</template>

<script>
import AuthHeader from '@/components/app/auth/AuthHeader';
import TextField from '@/components/app/form/TextField';
import Api from "@/api/Api";
import {mapActions, mapMutations} from "vuex";

export default {
    name: "ResetPassword",
    components: {
        AuthHeader,
        TextField
    },
    data: () => ({
        form: {
            email: '',
            token: '',
            password: '',
            password_confirmation: ''
        },
        errors: {},
    }),
    methods: {
        async send() {
            await Api.resetPassword(this.form)
                .then((response) => {
                    if (response.status === 200) {
                        this.errors = {}
                        console.log(response)
                        const token = response.data.token;
                        const user = response.data.user;
                        this.setUser({user, token}).then(() => {
                            this.$router.push({name: 'auth.home'})
                        })
                        this.showSnackBar(this.$t('dialogs.password_reset_confirmed'))
                    }
                })
                .catch((error) => {
                    if (error.response.data.errors) {
                        this.errors = error.response.data.errors;
                        return
                    }

                    this.showSnackBar(error.response.data.message)
                });
        },
        ...mapActions(['setUser']),
        ...mapMutations(['showSnackBar'])
    },
    mounted() {
        this.form.email = this.$route.query.email
        this.form.token = this.$route.query.token
        console.log(this.form)
    }
}
</script>
